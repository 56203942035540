import { graphql, Link } from 'gatsby'
import React, { Component } from 'react'
import Header from '../../components/header/header'
import posed from 'react-pose'
import styled from 'styled-components'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import WorkHeader from '../../components/work/workHeader'
import WorkHeaderMobile from '../../components/work/workHeaderMobile'
import BlankImage from '../../images/list-cover-blank.jpg'
import RowTextLarge from './rowTextLarge'
import RowImgCols from './rowImgCols'
import RowImgSingle from './rowImgSingle'
import RowTextCols from './rowTextCols'
import RowVideo from './rowVideo'

let PlaceholderImage = {}
PlaceholderImage.src = BlankImage

const PageAnimationWrapper = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 100, opacity: 0 },
})

const WorkSingleContainerWrapper = styled.div`
  color: ${props => props.theme.dark};
  padding: 0 20px 0px 20px;
`
const WorkAllLink = styled.div`
  text-align: center;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 40px;
  margin: 0 0 90px 0;
  a {
    border: 2px solid ${props => props.theme.dark};
    font-family: ${props => props.theme.poppins};
    color: ${props => props.theme.dark};
    padding: 12px 40px;
    border-radius: 4px;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    &:hover {
      background: ${props => props.theme.dark};
      color: ${props => props.theme.white};
      transform: scale(1.05);
    }
  }
`

class SingleWorkItem extends Component {
  state = {
    isVisible: false,
    isMobile: false,
  }
  componentDidMount() {
    // ANIMATIONS
    setTimeout(() => {
      this.setState({ isVisible: true })
    }, 500)
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
  }
  updateDimensions = () => {
    if (window.innerWidth < 768) {
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }
  render() {
    const { isVisible, isMobile } = this.state
    const { wordpressWpWork } = this.props.data

    return (
      <Layout lightMode>
        <SEO
          title={`${wordpressWpWork.acf.client} | Work`}
          keywords={[`design`, `developer`, `react`]}
        />
        <Header colour="#F5A623" lightMode={false} />
        {isMobile ? (
          <WorkHeaderMobile
            data={wordpressWpWork}
            img={
              wordpressWpWork.acf.image_mobile_page_header
                ? wordpressWpWork.acf.image_mobile_page_header.localFile
                    .childImageSharp.fluid
                : PlaceholderImage
            }
          />
        ) : (
          <WorkHeader
            data={wordpressWpWork}
            img={
              wordpressWpWork.acf.image_page_header
                ? wordpressWpWork.acf.image_page_header.localFile
                    .childImageSharp.fluid
                : PlaceholderImage
            }
          />
        )}
        <PageAnimationWrapper pose={isVisible ? 'visible' : 'hidden'}>
          <WorkSingleContainerWrapper>
            {wordpressWpWork.acf.rows.map((row, i) => {
              if (row.type === 'textLarge') {
                return (
                  <RowTextLarge
                    data={row}
                    color={wordpressWpWork.acf.color}
                    key={i}
                  />
                )
              } else if (row.type === 'imgCols') {
                return (
                  <RowImgCols
                    data={row}
                    color={wordpressWpWork.acf.color}
                    key={i}
                  />
                )
              } else if (row.type === 'imgSingle') {
                return (
                  <RowImgSingle
                    data={row}
                    color={wordpressWpWork.acf.color}
                    key={i}
                  />
                )
              } else if (row.type === 'textCols') {
                return (
                  <RowTextCols
                    data={row}
                    color={wordpressWpWork.acf.color}
                    key={i}
                  />
                )
              } else if (row.type === 'video') {
                return (
                  <RowVideo
                    data={row}
                    color={wordpressWpWork.acf.color}
                    key={i}
                  />
                )
              }
              return null
            })}
          </WorkSingleContainerWrapper>
          <WorkAllLink>
            <Link to="/work">Back to all Work</Link>
          </WorkAllLink>
        </PageAnimationWrapper>
      </Layout>
    )
  }
}
export default SingleWorkItem

export const query = graphql`
  query SingleWorkItems($slug: String!) {
    wordpressWpWork(slug: { eq: $slug }) {
      title
      content
      date(formatString: "DD MMMM YYYY")
      acf {
        color
        color_header_text
        client
        type
        deliverables {
          name
        }
        expertise {
          name
        }
        timeframe {
          label
        }
        image_list_bg {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 2400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_page_header {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 2400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_mobile_page_header {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        platforms {
          name
        }
        rows {
          type
          bg
          text_large
          text_columns
          text_align
          text_column_1_body
          text_column_1_heading
          text_column_2_body
          text_column_2_heading
          link
          is_compact
          image_columns {
            title
            caption
            localFile {
              childImageSharp {
                fluid(quality: 75, maxWidth: 2400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image_single {
            title
            localFile {
              childImageSharp {
                fluid(quality: 75, maxWidth: 2400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image_single_style
          video_heading
          video_body
          video_embed
        }
      }
      slug
    }
  }
`
