import React, { Component } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Row = styled.div`
  background-color: ${props => props.bgColor};
  margin: 0 -20px;
  padding: 0;
`
const RowInner = styled.div`
  max-width: ${props =>
    props.fullwidth ? '100%' : props.theme.maxWidthSkinny};
  margin: 0 auto;
  padding: 0;
`

class RowImgSingle extends Component {
  render() {
    const { data } = this.props

    let bgColor = '#fff'
    let fontColor = 'rgba(0, 0, 0, 0.8)'
    if (data.bg === 'lightGrey') {
      bgColor = '#f2f2f2'
    } else if (data.bg === 'darkGrey') {
      bgColor = '#15081E'
      fontColor = 'rgba(255, 255, 255, 0.9)'
    }
    console.log(data.image_single_style)
    return (
      <Row bgColor={bgColor} fontColor={fontColor}>
        <RowInner fullwidth={data.image_single_style === 'full'}>
          <Img fluid={data.image_single.localFile.childImageSharp.fluid} />
        </RowInner>
      </Row>
    )
  }
}
export default RowImgSingle
