import React, { Component } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Row = styled.div`
  background-color: ${props => props.bgColor};
  color: ${props => props.fontColor};
  margin: 0 -20px;
  padding: 20px;
`
const RowInner = styled.div`
  max-width: ${props => props.theme.maxWidthSkinny};
  margin: 0 auto;
  padding: 40px 0;
  color: ${props => props.fontColor};
  display: grid;
  grid-template-columns: ${props => (props.isSingle ? '1fr' : '1fr 1fr')};
  grid-gap: ${props => (props.isSingle ? '0' : '40px')};
  & > div:first-child {
    grid-column: 1 / span ${props => (props.hasFeatered ? '2' : '1')};
  }
  h3 {
    text-transform: capitalize;
  }
  .caption {
    color: ${props => props.fontColor};
    opacity: 0.5;
    padding: 20px;
    text-align: center;
    text-transform: capitalize;
    display: block;
  }
  div img {
    border: 1px solid #eee;
  }
  .image {
    text-align: center;
    a {
      color: ${props => props.fontColor};
      border: 1px solid #fff;
      opacity: 0.5;
      padding: 10px 20px;
      margin: 10px;
      border-radius: 8px;
      display: inline-block;
      transition: all 200ms ease-in-out;
      &:hover {
        border: 1px solid ${props => props.fontColor};
        opacity: 1;
      }
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    & > div:first-child {
      grid-column: 1 / span 1;
    }
  }
`
const MoreLink = styled.div`
  text-align: center;
  padding: 0 0 20px 0;
  opacity: 0.8;
  a {
    color: ${props => props.fontColor};
    border-bottom: 3px solid ${props => props.color};
    padding: 5px 10px;
    transition: all 0.2s ease-in-out;
    &:hover {
      padding: 5px 10px 10px 10px;
    }
  }
`

class RowImgCols extends Component {
  render() {
    const { data, color } = this.props

    let bgColor = '#fff'
    let fontColor = 'rgba(0, 0, 0, 0.8)'
    if (data.bg === 'lightGrey') {
      bgColor = '#f2f2f2'
    } else if (data.bg === 'darkGrey') {
      bgColor = '#15081E'
      fontColor = 'rgba(255, 255, 255, 0.9)'
    }

    const isSingle = data.image_columns.length === 1
    const hasFeatered = data.image_columns.length % 2 !== 0
    return (
      <Row bgColor={bgColor} fontColor={fontColor}>
        <RowInner
          isSingle={isSingle}
          fontColor={fontColor}
          hasFeatered={hasFeatered}
        >
          {data.image_columns.map((image, i) => {
            const isLink = image.title.includes('http')
            return (
              <div key={i} className="image">
                <Img fluid={image.localFile.childImageSharp.fluid} />
                {isLink ? (
                  <a
                    href={image.title}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {image.title.replace('https://', '')}
                  </a>
                ) : (
                  <span
                    className="caption"
                    dangerouslySetInnerHTML={{ __html: image.title }}
                  />
                )}
              </div>
            )
          })}
        </RowInner>
        {data.link && (
          <MoreLink color={color} fontColor={fontColor}>
            See More{' '}
            <a href={data.link} target="_blank" rel="noopener noreferrer">
              {data.link}
            </a>
          </MoreLink>
        )}
      </Row>
    )
  }
}
export default RowImgCols
