import React, { Component } from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import Img from 'gatsby-image'

const HeaderWrapper = posed.div({
  visible: { opacity: 1, delayChildren: 800, staggerChildren: 200 },
  hidden: { opacity: 0 },
})
const HeaderLabel = posed.div({
  visible: { x: 0, opacity: 1 },
  hidden: { x: -200, opacity: 0 },
})

const WorkHeading = styled(Img)`
  top: -94px;
  margin-bottom: -94px;
`
const Content = styled.div`
  h1 {
    background-color: ${props => props.theme.dark};
    border-left: 4px solid ${props => props.color};
    color: rgba(255, 255, 255, 1);
    font-size: 25px;
    margin: 0;
    padding: 20px;
  }
  h3 {
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    margin: 5px 0 2px 0;
    padding: 0;
    font-size: 18px;
  }
  .label {
    font-size: 12px;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.06);
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.08);
    letter-spacing: 0.5px;
    padding: 2px 12px;
    margin: 6px 6px 0 0;
    border-radius: 3px;
    display: inline-block;
    font-family: ${props => props.theme.poppins};
  }
  .meta {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 0 40px 0;
  }
  @media (max-width: 768px) {
    margin-bottom: -40px;
  }
  @media (max-width: 400px) {
    .meta {
      grid-template-columns: 1fr;
    }
  }
`

class WorkHeader extends Component {
  state = {
    isVisible: false,
    isVisibleHeading: false,
    isVisibleClient: false,
    isVisibleDeliverables: false,
    isVisiblePlatforms: false,
    isVisibleExpertise: false,
  }
  componentDidMount() {
    // ANIMATIONS
    setTimeout(() => {
      this.setState({ isVisible: true })
    }, 100)
    setTimeout(() => {
      this.setState({ isVisibleHeading: true })
    }, 600)
    setTimeout(() => {
      this.setState({ isVisibleClient: true })
    }, 900)
    setTimeout(() => {
      this.setState({ isVisibleDeliverables: true })
    }, 1200)
    setTimeout(() => {
      this.setState({ isVisiblePlatforms: true })
    }, 1500)
    setTimeout(() => {
      this.setState({ isVisibleExpertise: true })
    }, 1800)
  }

  render() {
    const { data, img } = this.props
    const {
      isVisible,
      isVisibleHeading,
      isVisibleClient,
      isVisibleDeliverables,
      isVisiblePlatforms,
      isVisibleExpertise,
    } = this.state

    const isDarkText = data.acf.color_header_text

    return (
      <HeaderWrapper pose={isVisible ? 'visible' : 'hidden'}>
        <WorkHeading fluid={img} />
        <Content isDarkText={isDarkText} color={data.acf.color}>
          <HeaderLabel pose={isVisibleHeading ? 'visible' : 'hidden'}>
            <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
          </HeaderLabel>
          <div className="meta">
            <HeaderLabel pose={isVisibleClient ? 'visible' : 'hidden'}>
              <h3>{data.acf.type === 'client' ? 'Client' : 'Product Name'}</h3>
              <div className="label">{data.acf.client}</div>
            </HeaderLabel>
            <HeaderLabel pose={isVisibleDeliverables ? 'visible' : 'hidden'}>
              {data.acf.deliverables && <h3>Deliverables</h3>}
              {data.acf.deliverables &&
                data.acf.deliverables.map(deliverable => (
                  <div className="label" key={deliverable.name}>
                    {deliverable.name}
                  </div>
                ))}
            </HeaderLabel>
            <HeaderLabel pose={isVisibleExpertise ? 'visible' : 'hidden'}>
              {data.acf.expertise && <h3>My Role</h3>}
              {data.acf.expertise &&
                data.acf.expertise.map(expertise => (
                  <div className="label" key={expertise.name}>
                    {expertise.name}
                  </div>
                ))}
            </HeaderLabel>
            <HeaderLabel pose={isVisiblePlatforms ? 'visible' : 'hidden'}>
              {data.acf.timeframe && <h3>Timeframe</h3>}
              {data.acf.timeframe &&
                data.acf.timeframe.map(timeframe => (
                  <div className="label" key={timeframe.label}>
                    {timeframe.label}
                  </div>
                ))}
            </HeaderLabel>
          </div>
        </Content>
      </HeaderWrapper>
    )
  }
}
export default WorkHeader
