import React, { Component } from 'react'
import styled from 'styled-components'

const Row = styled.div`
  background-color: ${props => props.bgColor};
  color: ${props => props.fontColor};
  margin: 0 -20px;
  padding: ${props => (props.isCompact ? '20px 20px 0 20px' : '20px')};
`
const RowInner = styled.div`
  max-width: ${props => props.theme.maxWidthSkinny};
  margin: 0 auto;
  padding: ${props => (props.isCompact ? '60px 0 0 0' : '60px 0')};
  color: ${props => props.fontColor};
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${props => (props.isSingle ? '0' : '40px')};
  text-align: ${props => props.align};
  h3 {
    text-transform: capitalize;
    font-size: 2rem;
  }
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

class RowVideo extends Component {
  render() {
    const { data } = this.props

    let bgColor = '#fff'
    let fontColor = 'rgba(0, 0, 0, 0.8)'
    if (data.bg === 'lightGrey') {
      bgColor = '#f2f2f2'
    } else if (data.bg === 'darkGrey') {
      bgColor = '#15081E'
      fontColor = 'rgba(255, 255, 255, 0.9)'
    }

    const isCompact = data.is_compact === 'yes'

    return (
      <Row bgColor={bgColor} fontColor={fontColor} isCompact={isCompact}>
        <RowInner align={data.text_align} isCompact={isCompact}>
          <div>
            <h3>{data.video_heading}</h3>
            <div dangerouslySetInnerHTML={{ __html: data.video_body }} />
            <div className="videoWrapper">
              <div dangerouslySetInnerHTML={{ __html: data.video_embed }} />
            </div>
          </div>
        </RowInner>
      </Row>
    )
  }
}
export default RowVideo
