import React, { Component } from 'react'
import styled from 'styled-components'

const Row = styled.div`
  background-color: ${props => props.bgColor};
  color: ${props => props.fontColor};
  margin: 0 -20px;
  padding: ${props => (props.isCompact ? '20px 20px 0 20px' : '20px')};
  .inpage-button {
    padding: 15px 20px;
    margin: 10px;
    display: inline-block;
    border-radius: 4px;
    color: ${props => props.fontColor};
    background-color: ${props => props.color};
    text-transform: uppercase;
    transition: all 200ms ease-in-out;
    &:hover {
      color: ${props => props.bgColor};
      background-color: ${props => props.fontColor};
    }
  }
  h4 {
    font-size: 1.3rem !important;
    line-height: 1.2;
    margin: 0;
  }
`
const RowInner = styled.div`
  max-width: ${props => props.theme.maxWidthSkinny};
  margin: 0 auto;
  padding: ${props => (props.isCompact ? '60px 0 0 0' : '60px 0')};
  color: ${props => props.fontColor};
  display: grid;
  grid-template-columns: ${props => (props.isSingle ? '1fr' : '1fr 1fr')};
  grid-gap: ${props => (props.isSingle ? '0' : '40px')};
  text-align: ${props => props.align};
  h3 {
    text-transform: capitalize;
    font-size: 2rem;
    margin: 0 0 20px 0;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 480px) {
    h3 {
      font-size: 1.8rem;
    }
  }
`

class RowTextCols extends Component {
  render() {
    const { data, color } = this.props

    let bgColor = '#fff'
    let fontColor = 'rgba(0, 0, 0, 0.8)'
    if (data.bg === 'lightGrey') {
      bgColor = '#f2f2f2'
    } else if (data.bg === 'darkGrey') {
      bgColor = '#15081E'
      fontColor = 'rgba(255, 255, 255, 0.9)'
    }

    const isSingle = data.text_columns === '1'
    const isCompact = data.is_compact === 'yes'

    return (
      <Row
        bgColor={bgColor}
        fontColor={fontColor}
        color={color}
        isCompact={isCompact}
      >
        <RowInner
          isSingle={isSingle}
          align={data.text_align}
          isCompact={isCompact}
        >
          <div>
            <h3>{data.text_column_1_heading}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: data.text_column_1_body }}
            />
          </div>
          {!isSingle && (
            <div>
              <h3>{data.text_column_2_heading}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: data.text_column_2_body }}
              />
            </div>
          )}
        </RowInner>
      </Row>
    )
  }
}
export default RowTextCols
