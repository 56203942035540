import React, { Component } from 'react'
import styled from 'styled-components'
import iconQuoteOpen from '../../images/icon-quote-open.svg'

const Row = styled.div`
  background-color: ${props => props.bgColor};
  color: ${props => props.fontColor};
  margin: 0 -20px;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .inpage-button {
    padding: 15px 20px;
    margin: 10px;
    display: inline-block;
    border-radius: 4px;
    color: ${props => props.fontColor} !important;
    background-color: ${props => props.color} !important;
    text-transform: uppercase;
    transition: all 200ms ease-in-out;
    &:hover {
      background-color: ${props => props.fontColor} !important;
    }
  }
`
const Inner = styled.div`
  max-width: ${props => props.theme.maxWidthSkinny};
  margin: 0 auto;
  padding: 60px 0;
  color: ${props => props.fontColor};
  p {
    color: ${props => props.fontColor};
    font-size: 30px;
    line-height: 40px;
    margin: 0;
  }
  blockquote {
    margin: 0;
    padding: 0 0 0 60px;
    position: relative;
    &:before {
      content: url(${iconQuoteOpen});
      position: absolute;
      top: -10px;
      left: 0;
    }
    p {
      margin: 1em 0;
    }
    .quote-name {
      font-size: 23px;
      line-height: 20px;
    }
    .quote-position {
      font-size: 18px;
      line-height: 20px;
      opacity: 0.5;
    }
  }
  @media (max-width: 500px) {
    p {
      font-size: 25px;
      line-height: 35px;
    }
  }
  @media (max-width: 500px) {
    blockquote {
      padding: 0;
      &:before {
        top: -50px;
      }
    }
  }
`

class RowTextLarge extends Component {
  render() {
    const { data, color } = this.props

    let bgColor = '#fff'
    let fontColor = 'rgba(0, 0, 0, 0.8)'
    if (data.bg === 'lightGrey') {
      bgColor = '#f2f2f2'
    } else if (data.bg === 'darkGrey') {
      bgColor = '#15081E'
      fontColor = 'rgba(255, 255, 255, 0.9)'
    }

    return (
      <Row bgColor={bgColor} fontColor={fontColor} color={color}>
        <Inner bgColor={bgColor} fontColor={fontColor}>
          <div dangerouslySetInnerHTML={{ __html: data.text_large }} />
        </Inner>
      </Row>
    )
  }
}
export default RowTextLarge
